
















































import { Component, Vue } from "vue-property-decorator";
import GameCanvas from "../components/Game/GameCanvas.vue";
import { namespace } from "vuex-class";
import {
  GAME_STATUS_CHANGED_EVENT,
  MessageData,
  MESSAGE_EVENT,
  SEND_MESSAGE_EVENT,
} from "../../shared/events";
import Game from "../../shared/chess/game";

const gameState = namespace("gameState");

@Component({
  components: { GameCanvas },
  sockets: {
    [GAME_STATUS_CHANGED_EVENT]: function (this: GameComponent) {
      // TODO: Change this to modal
      alert(this.game?.statusMsg);
    },
    [MESSAGE_EVENT]: function (this: GameComponent, data: MessageData) {
      this.chatMessages.push(data);
    },
  },
})
export default class GameComponent extends Vue {
  // State
  @gameState.Getter opponentName!: string | undefined;
  @gameState.Getter myName!: string | undefined;
  @gameState.Getter myIdentity!: string | undefined;
  @gameState.Getter numSpectators!: string | undefined;
  @gameState.Getter joinUrl!: string | undefined;
  @gameState.State game!: Game | null;

  chatMessages: MessageData[] = [];
  currentlyTypingMessage = "";

  // Hooks
  created(): void {
    if (!this.game) {
      this.$router.replace({ path: "/", query: this.$route.query });
      return;
    }
  }

  // Methods
  sendMessage(): void {
    const msg = this.currentlyTypingMessage.trim();
    console.log(`Sending message ${msg}`);
    console.log(`Message length: ${msg.length}`);
    if (msg.length == 0) return;
    this.$socket.client.emit(SEND_MESSAGE_EVENT, msg);
    this.currentlyTypingMessage = "";
  }

  leaveGamePressed(): void {
    console.log("Leave game pressed");
    this.$socket.client.disconnect();
    this.$router.back();
  }

  async copyJoinUrl(): Promise<void> {
    if (!this.joinUrl) return;
    await navigator.clipboard.writeText(this.joinUrl);
    this.$notify({ text: "Copied to clipboard!" });
  }

  // Getters
  get statusMsg(): string | undefined {
    return this.game?.statusMsg;
  }
}
